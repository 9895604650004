import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "event-popup"
};
const _hoisted_2 = {
  class: "popup-main"
};
const _hoisted_3 = {
  class: "popup-header"
};
const _hoisted_4 = {
  class: "popup-title"
};
const _hoisted_5 = {
  class: "popup-content"
};
const _hoisted_6 = {
  class: "popup-content-left"
};
const _hoisted_7 = {
  class: "event-list"
};
const _hoisted_8 = ["onClick"];
const _hoisted_9 = {
  class: "event-item-title"
};
const _hoisted_10 = {
  class: "popup-content-right"
};
import { ref, onMounted } from 'vue';
import { useHelpTool } from "@/hooks";
import { usePopupStore } from "@/store";
import { queryEvent } from "@/api";
export default {
  __name: 'Event',
  emits: ['onClose'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const {
      doEnterGame,
      openBrowser
    } = useHelpTool();
    const popupStore = usePopupStore();
    const eventList = ref([]);
    const activeEvent = ref({});
    onMounted(() => {
      fetchEvent();
    });
    const fetchEvent = () => {
      queryEvent().then(res => {
        eventList.value = res.data || [];
        if (eventList.value.length > 0) {
          activeEvent.value = eventList.value[0];
        }
      });
    };
    const doGo = item => {
      const {
        action,
        actionContent,
        actionData
      } = item || {};
      if (action === 'url') {
        openBrowser(actionContent);
      } else if (action === 'game') {
        doEnterGame(actionContent);
      } else if (action === 'page') {
        switch (actionContent) {
          case 'recharge':
            emit('onClose');
            if (actionData) {
              popupStore.goToDeposit(actionData);
            } else {
              popupStore.set('showDeposit');
            }
            break;
          case 'task':
            emit('onClose');
            popupStore.set('showTask');
            break;
          case 'invite':
            emit('onClose');
            popupStore.set('showLuckyWheel');
            break;
          case 'vip':
            emit('onClose');
            popupStore.set('showVip');
            break;
          case 'signUp':
            emit('onClose');
            popupStore.set('showDaliyBonus');
            break;
          case 'redeem':
            emit('onClose');
            popupStore.set('showRedeem');
            break;
          case 'follow':
            emit('onClose');
            popupStore.set('showSocialMedia');
            break;
          case 'bind':
            emit('onClose');
            popupStore.set('showProfile');
            break;
        }
      }
    };
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_loading = _resolveComponent("van-loading");
      const _component_van_popup = _resolveComponent("van-popup");
      const _directive_audio = _resolveDirective("audio");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
        show: true,
        "close-on-click-overlay": false,
        "lock-scroll": false
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('event.title')), 1), _withDirectives(_createVNode(_component_van_image, {
          class: "close-icon",
          src: require('@/assets/img/common/popup-close.png'),
          onClick: _cache[0] || (_cache[0] = $event => emit('onClose'))
        }, null, 8, ["src"]), [[_directive_audio, void 0, "close"]])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(eventList.value, item => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(`event-item ${item.id === activeEvent.value.id ? 'active' : ''}`),
            key: item.id,
            onClick: $event => activeEvent.value = item
          }, [_createElementVNode("div", _hoisted_9, _toDisplayString(item.title), 1)], 10, _hoisted_8)), [[_directive_audio]]);
        }), 128))])]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_van_image, {
          fit: "cover",
          src: activeEvent.value.img || '-',
          "error-icon": require('@/assets/img/common/game.png'),
          onClick: _cache[1] || (_cache[1] = $event => doGo(activeEvent.value))
        }, {
          loading: _withCtx(() => [_createVNode(_component_van_loading, {
            type: "spinner",
            color: "#ADB5BF",
            size: "24"
          })]),
          _: 1
        }, 8, ["src", "error-icon"])])])])]),
        _: 1
      })]);
    };
  }
};